<template>
    <div class="bindBankCard">
        <div class="navbar">
            <div class="navbar_btn" @click="$router.go(-1)">
                <svg-icon iconClass="leftArrow"></svg-icon>
            </div>
            <div class="navbar_title">{{isModify ? '更换银行卡' : '绑定银行卡'}}</div>
        </div>

        <div class="body">
            <div class="inputBox">
                <van-field v-model="name" placeholder="请输入真实姓名" />
            </div>
            <div class="inputBox">
                <van-field v-model="bankName" placeholder="请输入开户行名称如工商银行" />
            </div>
            <div class="inputBox">
                <van-field v-model="bankCardNo" placeholder="请输入账号卡号" />
            </div>
            <div class="tip">*请确保银行卡信息正确，若错误会导致兑换失败</div>
            <div class="confirmBtn" @click="checkBankCard">确认</div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import {
        queryBankCard,
        bindBankCard,
        modifyBankCard,
    } from '@/api/user.js';
    export default {
        data() {
            return {
                name: '',
                bankName: '',
                bankCardNo: '',
                isModify: false,
            }
        },
        created() {
            this.queryBankCard();
            
        },
        methods: {
            async queryBankCard() {
                let ret = await this.$Api(queryBankCard);
                if (ret && ret.code == 200) {
                    this.name = ret.data.bankInfo.name;
                    this.bankName = ret.data.bankInfo.bankType;
                    this.bankCardNo = ret.data.bankInfo.cardNum;
                    if (ret.data.bankInfo.bankType) {
                        this.isModify = true;
                    }
                }
            },

            // 校验银行卡
            checkBankCard() {
                let retUrl =
                    `https://ccdcapi.alipay.com/validateAndCacheCardInfo.json?_input_charset=utf-8&cardBinCheck=true&cardNo=${this.bankCardNo}`;
                // console.log(retUrl)
                if (!this.name) {
                    this.$toast('请输入真实姓名');
                    return;
                }
                if (!this.bankName) {
                    this.$toast('请输入开户行名称');
                    return;
                }
                if (!this.bankCardNo) {
                    this.$toast('请输入银行卡号');
                    return;
                }
                axios.get(retUrl)
                    .then(res => {
                        if (res.status === 200 && res.data.validated && res.data.bank && res.data.cardType) {
                            let req = {
                                name: this.name,
                                cardNum: res.data.key,
                                bankCode: res.data.bank,
                                bankType: this.bankName,
                            }
                            // return;
                            this.isModify ? this.modifyBankCard(req) : this.bindBankCard(req);
                        } else {
                            this.$toast('请输入正确的银行卡号');
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
                // .finally(i => {
                //     console.log(i)
                // })
            },
            // 绑定银行卡
            async bindBankCard(queryData) {
                let req = queryData;
                let ret = await this.$Api(bindBankCard, req);
                if (ret && ret.code === 200) {
                    if (this.$route.query.t) {
                        this.$router.replace('goldWithdraw');
                    } else {
                        this.$router.go(-1);
                    }
                } else {
                    this.$toast(ret.tip || '绑定失败')
                }
            },
            // 修改银行卡
            async modifyBankCard(queryData) {
                let req = queryData;
                let ret = await this.$Api(modifyBankCard, req);
                if (ret && ret.code === 200) {
                    this.$router.go(-1);
                } else {
                    this.$toast(ret.tip || '修改失败')
                }
            }
        }
    }
</script>

<style lang='scss' scoped>
    .bindBankCard {
        .navbar {
            // position: fixed;
            // top: 0;
            background: $vermillion;
            // width: 100%;
            color: $white1;
            height: 44px;
            font-size: 20px;
            padding: 0 16px;

            display: flex;
            align-items: center;

            .navbar_btn {
                margin-right: 28px;
            }

            .navbar_title {
                font-weight: 600;
            }
        }

        .body {
            padding: 18px 16px 0;

            .inputBox {
                height: 44px;
                margin: 0 auto;
                background: rgba(242, 19, 19, 0.18);
                border-radius: 22px;
                margin-bottom: 12px;
                padding: 0 10px;
                box-sizing: border-box;

                /deep/ .van-cell {
                    background: none;
                }

                /deep/input::-webkit-input-placeholder {
                    color: #515151;
                    font-size: 14px;
                }

                /deep/input::-ms-input-placeholder {
                    color: #515151;
                    font-size: 14px;
                }

                /deep/input::-moz-placeholder {
                    color: #515151;
                    font-size: 14px;
                }

                /deep/input::-moz-placeholder {
                    color: #515151;
                    font-size: 14px;
                }
            }

            .tip {
                color: $greyishBrown;
                font-size: 13px;
            }

            .confirmBtn {
                height: 42px;
                width: 100%;
                border-radius: 21px;
                line-height: 42px;
                font-size: 20px;
                margin-top: 26px;
                background: $vermillion;
                text-align: center;
                color: $white1;
            }
        }
    }
</style>